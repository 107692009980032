<template>
	<div>
		<jy-query ref="form" :model="formInline">
			<jy-query-item prop="startTime" label="开始时间">
				<el-date-picker
					v-model="formInline.startDate"
					:picker-options="startTime"
					value-format="yyyy-MM-dd"
					type="datetime"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<!-- <el-col :span="2" class="item">至</el-col> -->
			<jy-query-item prop="endTime" label="结束时间">
				<el-date-picker
					v-model="formInline.endTime"
					:picker-options="endTime"
					value-format="yyyy-MM-dd"
					type="datetime"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="formInline.routeIds" multiple>
					<el-option v-for="one in routeOptions" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('formInline')">重置</el-button>
				<!-- <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('formInline')" v-if="btnexist('reset')">重置</el-button> -->
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路名称"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号码"></jy-table-column>
			<jy-table-column prop="sTime" label="发车时间"></jy-table-column>
			<jy-table-column prop="eTime" label="结束时间"></jy-table-column>
			<jy-table-column label="方向" min-width="160">
				<template slot-scope="scope">{{ scope.row.rType | rType }}</template>
			</jy-table-column>
			<jy-table-column prop="sSiteNa" label="起点站"></jy-table-column>
			<jy-table-column prop="eSiteNa" label="终点站"></jy-table-column>
			<jy-operate v-bind:list="operationList" width="345"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<offtrack ref="track"></offtrack>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
import offtrack from "@/components/pages/admin/statisticalAnalysis/lindeviationTotal/offtrack";
export default {
	data() {
		return {
			formInline: {
				startDate: "",
				startTime: "",
				routeIds: [],
				endDate: "",
				endTime: ""
			},
			dataList: [],
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			routeOptions: [],
			startTime: {
				disabledDate: time => {
					if (this.formInline.endTime) {
						return time.getTime() > new Date(this.formInline.endTime).getTime();
					} else {
						return time.getTime() > Date.now() - 8.64e7;
					}
				}
			},
			endTime: {
				disabledDate: time => {
					if (this.formInline.startTime) {
						return (
							time.getTime() > Date.now() - 8.64e7 || time.getTime() <= new Date(this.formInline.startTime).getTime() - 8.64e7 //这里的this.params.beginDate按自己的字段参数来
						);
					} else {
						return time.getTime() > Date.now() - 8.64e7;
					}
				}
			}
		};
	},
	async created() {
		this.seOperation();
		await this.getRouteList();
		this.getList();
	},
	mixins: [btnMixins],
	components: {
		offtrack
	},
	filters: {
		rType(val) {
			if (val == 0) {
				return "上下行 ";
			} else if (val == 1) {
				return "环形";
			}
		}
	},
	methods: {
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		seOperation() {
			this.operationList = [
				{
					name: "轨迹回放",
					fun: item => {
						this.track(item);
					},
					isShow: () => {
						return true;
					}
				}
			];
		},
		getList() {
			let url = "stat/line/deviation/queryPage";
			let option = {
				...this.formInline,
				pageSize: this.pageSize,
				pageIndex: this.pageIndex
			};
			this.$http.post(url, option).then(({ detail }) => {
				detail.list.forEach(item => {
					item.sTime = item.oDate + " " + item.sTime;
					item.eTime = item.oDate + " " + item.eTime;
					return {
						...item
					};
				});
				this.dataList = detail.list;
				this.total = detail.total;
				console.log("detail", this.dataList);
			});
		},
		// 查询表格
		oncheck() {
			this.getList();
		},
		// 重置
		resetForm() {
			// this.$refs[formInline].resetFields()
			this.formInline.routeIds = [];
			this.formInline.startDate = "";
			this.formInline.endDate = "";
			this.pageIndex = 1;
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getList();
		},
		track(val) {
			console.log(val);
			this.$refs.track.init(val);
		}
	}
};
</script>
<style scoped>
.total {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	height: 54px;
	background: inherit;
	background-color: rgba(215, 215, 215, 1);
	padding: 20px;
	font-size: initial;
}
.formInfo {
	margin-top: 20px;
}
.item {
	height: 50px;
	text-align: center;
	padding-top: 5px;
	font-size: 16px;
}
</style>
